@charset "utf-8";

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    color: #333;
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans JP', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'Meiryo', sans-serif;
    font-size: 0.9375rem;
    overscroll-behavior-y: contain; /* prevent pull-to-refresh on chrome for mobile version */
}

h1 {
    font-size: 2.25rem;
}

h2 {
    font-size: 1.625rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1rem;
}

.big {
    font-size: 1.25rem;
}

.small {
    font-size: 0.875rem;
}

a {
    text-decoration: none;
}

/* Sidebarが不要なページ */
aside.noSidebar {
    display: none;
}

main.noSidebar {
    max-width: 600px;
    margin: 0 auto;
}

.App {
    text-align: center;
    max-width: 1920px;
    margin: 0 auto;
}

.Error {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    src: local('Noto Sans CJK JP Regular'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}
@font-face {
    font-family: 'Noto Sans JP';
    font-style: bold;
    font-weight: 700;
    src: local('Noto Sans CJK JP Bold'), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff2) format('woff2'),
        url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.woff) format('woff'), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Bold.otf) format('opentype');
}

input::-ms-clear {
    display: none;
}
input::-ms-reveal {
    display: none;
}
